/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, MenuOutlined, UserOutlined, QuestionCircleOutlined, BellOutlined, CloseOutlined } from '@ant-design/icons';
import { Layout, Avatar, Badge, Modal, List, Tabs, Button, Tag, message, Pagination } from 'antd';
import laundroLogo from '../images/logo/laundrologo.png';
import cleanproLogo from '../images/logo/cleanprologo-header.png';
import octopusLogo from '../images/logo/octopus-header.png';
import aerosparkleLogo from '../images/logo/aerosparklelogo.png';
import washstudioLogo from '../images/logo/washstudiologo.png';
import cuciLogo from '../images/logo/cuciLogo.png';

import avatar from '../images/avatars/thumb-13.jpg';
import cleanproBear from '../images/avatars/cleanpro-bear.png';
import ProfileDropDown from './ProfileDropDown';
import LanguageChanger from './LanguageChanger';
import { fetchNotifications, markNotificationAsRead, markAllNotificationsAsRead, } from '../reducer/reviewDashReducer';

const { Header } = Layout;
const { TabPane } = Tabs;

const HeaderComponent = ({ collapsed, setCollapsed, setShowDrawer, showDrawer }) => {

	const { notifications } = useSelector((state) => state.reviewR);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { theme } = useSelector((state) => state.theme);
	const [logo, setLogo] = useState(null);
	const [headerAvatar, setHeaderAvatar] = useState(null);
	const [unreadPage, setUnreadPage] = useState(1);
	const [readPage, setReadPage] = useState(1);

	const dispatch = useDispatch();

	useEffect(() => {
		//put some delay before fetching notification for token
		const timer = setTimeout(() => {
		  dispatch(fetchNotifications());
		}, 2000);
	  
		return () => clearTimeout(timer);
	  }, [dispatch]);
	  

	useEffect(() => {
		if (theme === 'laundro') {
			setLogo(laundroLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'cleanpro') {
			setLogo(cleanproLogo);
			setHeaderAvatar(cleanproBear);
		} else if (theme === 'octopus') {
			setLogo(octopusLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'washstudio') {
			setLogo(washstudioLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'cuci') {
			setLogo(cuciLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'aerosparkle') {
			setLogo(aerosparkleLogo);
			setHeaderAvatar(avatar);
		}
	}, [theme]);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const scrollableListStyle = {
		maxHeight: '400px',
		overflowY: 'auto',
		marginBottom: '20px'
	};

	return (
		<div>
			<Header className='site-layout-background header-content-navbar' mode='horizontal'>
				<div>
					{collapsed ? null : (
						<img
							className='laundro-logo ms-4'
							src={logo}
							style={{ width: '100%', objectFit: 'contain', marginTop: theme === 'washstudio' ? '1rem' : '0' }}
							alt='brand'
						/>
					)}
				</div>
				<div className='header-navbar-links'>
					{/* Desktop view */}
					<div onClick={() => setCollapsed(!collapsed)} style={{ color: '#fff' }} className='desktop-view-menu'>
						{collapsed ? (
							<ArrowLeftOutlined className='icons-styling' />
						) : (
							<MenuOutlined className='icons-styling ms-5' />
						)}
					</div>

					{/* Mobile view */}
					<div onClick={() => setShowDrawer(true)} style={{ color: '#fff' }} className='mobile-view-menu'>
						<MenuOutlined className='icons-styling' />
					</div>

					<div className='d-flex align-items-center'>
						<div className='my-select-container mb-2' style={{ marginRight: '15px' }}>
							<LanguageChanger />
						</div>

						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Badge
								count={notifications?.data?.filter(item => !item.read).length || 0}
								overflowCount={99}
								style={{ backgroundColor: '#ff4d4f', marginTop: '5px' }}
							>
								<Avatar
									size={45}
									icon={<BellOutlined style={{ color: 'white', fontSize: '30px' }} />}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										backgroundColor: '#3baea0',
										cursor: 'pointer'
									}}
									onClick={showModal}
									alt="Notification icon"
								/>
							</Badge>

							<Modal
								title="Notifications"
								open={isModalVisible}
								onCancel={handleCancel}
								footer={[
									<Button
										key="markAll"
										type="primary"
										onClick={() => dispatch(markAllNotificationsAsRead())}
									>
										Mark All as Read
									</Button>,
								]}
								bodyStyle={{ maxHeight: '600px', overflow: 'hidden' }}
							>
								<Tabs defaultActiveKey="1">
									<TabPane tab="Unread" key="1">
										<div style={scrollableListStyle}>
											<List
												itemLayout="horizontal"
												dataSource={(notifications?.data?.filter(item => !item.read) || [])
													.slice((unreadPage - 1) * 7, unreadPage * 7)}
												renderItem={item => (
													<List.Item
														actions={[
															<CloseOutlined
																onClick={() => {
																	dispatch(markNotificationAsRead(item.id));
																	message.success('Notification marked as read');
																	setTimeout(() => {
																		dispatch(fetchNotifications());
																	}, 500);
																}}
																style={{ cursor: 'pointer', color: 'red' }}
															/>
														]}
													>
														<List.Item.Meta
															title={<Tag color="blue">{item.category}</Tag>}
															description={item.description}
														/>
													</List.Item>
												)}
											/>
										</div>
										<Pagination
											current={unreadPage}
											onChange={setUnreadPage}
											total={notifications?.data?.filter(item => !item.read).length || 0}
											pageSize={7}
											style={{ marginTop: '1rem', textAlign: 'right' }}
										/>
									</TabPane>
									<TabPane tab="Read" key="2">
										<div style={scrollableListStyle}>
											<List
												itemLayout="horizontal"
												dataSource={(notifications?.data?.filter(item => item.read) || [])
													.slice((readPage - 1) * 7, readPage * 7)}
												renderItem={item => (
													<List.Item>
														<List.Item.Meta
															title={<Tag color="blue">{item.category}</Tag>}
															description={item.description}
														/>
													</List.Item>
												)}
											/>
										</div>
										<Pagination
											current={readPage}
											onChange={setReadPage}
											total={notifications?.data?.filter(item => item.read).length || 0}
											pageSize={7}
											style={{ marginTop: '1rem', textAlign: 'right' }}
										/>
									</TabPane>
								</Tabs>
							</Modal>

							<ProfileDropDown>
								<div className='logo-avatar'>
									<Avatar
										size={45}
										icon={<UserOutlined style={{ color: 'white', fontSize: '30px' }} />}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											backgroundColor: '#3baea0'
										}}
										alt="user icon"
									/>
								</div>
							</ProfileDropDown>

							<a href="https://info.thelaundro.com/faq/" target="_blank" rel="noopener noreferrer">
								<Avatar
									size={45}
									icon={<QuestionCircleOutlined style={{ color: 'white', fontSize: '30px' }} />}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										backgroundColor: '#3baea0',
										marginRight: '15px'
									}}
									alt="FAQ icon"
								/>
							</a>
						</div>
					</div>
				</div>
			</Header>
		</div>
	);
};

export default HeaderComponent;
