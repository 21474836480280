import React, { useEffect, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import MainPageLayout from './components/MainPageLayout';
import { updateTheme } from './reducer/themeReducer';
import { useDispatch } from 'react-redux';
import AdminPageLayout from './components/AdminPageLayout';
import DoPaymentCheck from './view/TheLaundro/Auth/DoPaymentCheck';
import ManagementUserSettingWrapper from './view/TheLaundro/User/ManagementUserSettingWrapper';
import ForgotPasswordPage from './view/TheLaundro/Auth/ForgotPasswordPage';
import VerifyResetPassword from './view/TheLaundro/Auth/VerifyResetPassword.js';

const PrivacyPolicy = lazy(() => import('./view/TheLaundro/Auth/PrivacyPolicy'));
const TermsConditions = lazy(() => import('./view/TheLaundro/Auth/Terms&Conditions'));

// auth
const OperatorLogin = lazy(() => import('./view/TheLaundro/Auth/OperatorLogin.js'));
const OperatorRegister = lazy(() => import('./view/TheLaundro/Auth/OperatorRegister.js'));
const OperatorVerify = lazy(() => import('./view/TheLaundro/Auth/OperatorVerify.js'));

// Admin login
const AdminLogin = lazy(() => import('./view/TheLaundro/Auth/AdminLogin'));

const DistributorLogin = lazy(() => import('./view/TheLaundro/Auth/DistributorLogin.js'));

// Staff login
const StaffLogin = lazy(() => import('./view/TheLaundro/Auth/staff/StaffLogin'));
const StaffRegister = lazy(() => import('./view/TheLaundro/Auth/staff/StaffRegister'));

// user
const UserProfile = lazy(() => import('./view/TheLaundro/User/UserProfile.js'));
const UserSetting = lazy(() => import('./view/TheLaundro/User/UserSetting.js'));

const UsersListWithRoles = lazy(() => import('./view/TheLaundro/User/UsersListWithRoles'));
const RoleListPage = lazy(() => import('./view/TheLaundro/User/RoleListPage'));
const UserActivityPage = lazy(() => import('./view/TheLaundro/User/UserActivityPage'));

const SummaryDashboard = lazy(() => import('./view/TheLaundro/Dashboard/SummaryDashboard.js'));
const SalesPerformance = lazy(() => import('./view/TheLaundro/Sales Performance/SalesPerformance.js'));
const MachineUtilization = lazy(() => import('./view/TheLaundro/Machine Utilization/MachineUtilization.js'));
const RemoteActivation = lazy(() => import('./view/TheLaundro/Remote Activation/RemoteActivation.js'));
const UtilityMonitoring = lazy(() => import('./view/TheLaundro/Utility Monitoring/UtilityMonitoring.js'));
// browsable reports
const TransactionsRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/TransactionsRecord.js'));
const EpayRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/EpayRecord.js'));
const DNGRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/DNGRecord.js'));
const ManualPayRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/ManualPayRecord.js'));
const ErrorCodeRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/ErrorCodeRecord.js'));
const BillRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/BillRecord.js'));
const VendingRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/VendingRecord.js'));
const CostingRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/CostingRecord.js'));
const MachineEfficiency = lazy(() => import('./view/TheLaundro/Browsable Reports/MachineEfficiency.js'));
const TerminalRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/TerminalRecord.js'));
const OverallEpaymentRecord = lazy(() => import('./view/TheLaundro/Browsable Reports/OverallEpaymentRecord.js'));


const SalesSummary = lazy(() => import('./view/TheLaundro/Browsable Reports/SalesSummary.js'));
const MachineSalesTransactions = lazy(() => import('./view/TheLaundro/Browsable Reports/MachineSalesTransactions.js'));

const HourlySalesTransaction = lazy(() => import('./view/TheLaundro/Browsable Reports/HourlySalesTransaction.js'));
const SalesByMachineType = lazy(() => import('./view/TheLaundro/Browsable Reports/SalesByMachineType.js'));
const SystemErrorReport = lazy(() => import('./view/TheLaundro/Browsable Reports/SystemErrorReport.js'));

const SalesSummaryOverall = lazy(() => import('./view/TheLaundro/Browsable Reports/SalesSummaryOverall.js'));
const WeeklyReport = lazy(() => import('./view/TheLaundro/Browsable Reports/WeeklyReport.js'));

//price setting
const PriceList = lazy(() => import('./view/TheLaundro/Others/PriceSetting/PriceList.js'));
const PriceList2 = lazy(() => import('./view/TheLaundro/Inventory/ChemicalStockSetting/PriceList2.js'));
const PromotionList = lazy(() => import('./view/TheLaundro/Others/Promotion/PromotionList'));
const Inventory = lazy(() => import('./view/TheLaundro/Others/Inventory'));
const DropPriceList = lazy(() => import('./view/TheLaundro/Others/DropServiceSetting/PriceList'));
const CostingList = lazy(() => import('./view/TheLaundro/Others/CostingSetting/CostingList.js'));
const SalesList = lazy(() => import('./view/TheLaundro/Others/SalesSetting/SalesList.js'));
const CostingLog = lazy(() => import('./view/TheLaundro/Others/CostingSetting/InputCost.js'));
const SalesLog = lazy(() => import('./view/TheLaundro/Others/SalesSetting/InputsSales.js'));
const ImportData = lazy(() => import('./view/TheLaundro/Others/ImportManagement/ImportData.js'));

const ReviewDashboard = lazy(() => import('./view/TheLaundro/Review Dashboard/ReviewDashboard'));

const MachineLogging = lazy(() => import('./view/TheLaundro/Machine Logging/MachineLogging'));

const DevRoleMainDashboard = lazy(() => import('./view/TheLaundro/Role Management/DevRoleMainDashboard'));
const RoleList = lazy(() => import('./view/TheLaundro/Role Management/RoleList'));
const SyncManagement = lazy(() => import('./view/TheLaundro/Role Management/SyncManagement'));

const RoleUserList = lazy(() => import('./view/TheLaundro/Role Management/RoleUserList'));
const DistributorsDashboard = lazy(() => import('./view/TheLaundro/Role Management/DistributorsDashboard'));

const OutletOnlineStatus = lazy(() => import('./view/TheLaundro/Dashboard/OutletOnlineStatus'));

const CoinCollectionPage = lazy(() => import('./view/TheLaundro/Coin Collection/CoinCollectionPage'));
const CoinCollectionGroup = lazy(() => import('./view/TheLaundro/Coin Collection/CoinCollectionGroup'));
const StartCollection = lazy(() => import('./view/TheLaundro/Coin Collection/StartCollection'));
const CutoffHistory = lazy(() => import('./view/TheLaundro/Coin Collection/CutoffHistory'));

const CoinCollectionByOutlet = lazy(() => import('./view/TheLaundro/Coin Collection Report/CoinCollectionByOutlet'));
const CoinCollectionByMachines = lazy(() =>
	import('./view/TheLaundro/Coin Collection Report/CoinCollectionByMachines')
);
const BillCollectionReport = lazy(() => import('./view/TheLaundro/Coin Collection Report/BillCollectionReport'));
const InventoryManagement = lazy(() => import('./view/TheLaundro/Inventory/InventoryManagement.js'));

//Inventory
const Chemicalstock = lazy(() => import('./view/TheLaundro/Inventory/ChemicalStock.js'));
const Inputstock = lazy(() => import('./view/TheLaundro/Inventory/InputStock.js'));
const ChemicalStockSetting = lazy(() => import('./view/TheLaundro/Inventory/ChemicalStockSetting.js'));
const ChemicalBalanceReport = lazy(() => import('./view/TheLaundro/Inventory/ChemicalBalanceReport.js'));

function App() {
	const dispatch = useDispatch();
	useEffect(() => {
		if (window.location.href.includes('localhost')) {
			dispatch(updateTheme('laundro'));

			document.title = 'The Laundro';
			document.querySelector('#my-icon').setAttribute('href', './laundro/favicon.ico');
			document.querySelector('#my-apple-icon').setAttribute('href', './laundro/logo192.png');
			document.querySelector('#my-manifest-placeholder').setAttribute('href', './laundro/manifest.json');
			return;
		}

		if (window.location.href.includes('octopus')) {
			dispatch(updateTheme('octopus'));
			// title replacement

			document.title = 'Washupp';
			// favicon replacement
			document.querySelector('#my-icon').setAttribute('href', './octopus/favicon.ico');
			document.querySelector('#my-apple-icon').setAttribute('href', './octopus/logo192.png');
			// manifest json replace
			document.querySelector('#my-manifest-placeholder').setAttribute('href', './octopus/manifest.json');
		} else if (window.location.href.includes('cuci')) {
			dispatch(updateTheme('cuci'));

			document.title = 'Laundro';
			document.querySelector('#my-icon').setAttribute('href', './cuciexpress/favicon.ico');
			document.querySelector('#my-apple-icon').setAttribute('href', './cuciexpress/logo192.png');
			document.querySelector('#my-manifest-placeholder').setAttribute('href', './cuciexpress/manifest.json');
		} else if (window.location.href.includes('cleanpro')) {
			dispatch(updateTheme('cleanpro'));

			document.title = 'Cleanpro';
			document.querySelector('#my-icon').setAttribute('href', './cleanpro/favicon.ico');
			document.querySelector('#my-apple-icon').setAttribute('href', './cleanpro/logo192.png');
			document.querySelector('#my-manifest-placeholder').setAttribute('href', './cleanpro/manifest.json');
		} else if (window.location.href.includes('washstudio')) {
			dispatch(updateTheme('washstudio'));

			document.title = 'Wash Studio';
			document.querySelector('#my-icon').setAttribute('href', './washstudio/favicon.ico');
			document.querySelector('#my-apple-icon').setAttribute('href', './washstudio/logo192.png');
			document.querySelector('#my-manifest-placeholder').setAttribute('href', './washstudio/manifest.json');
		} else if (window.location.href.includes('aerosparkle')) {
			dispatch(updateTheme('aerosparkle'));

			document.title = 'Aerosparkle';
			document.querySelector('#my-icon').setAttribute('href', './aerosparkle/favicon.ico');
			document.querySelector('#my-apple-icon').setAttribute('href', './aerosparkle/logo192.png');
			document.querySelector('#my-manifest-placeholder').setAttribute('href', './aerosparkle/manifest.json');
		} else {
			dispatch(updateTheme('laundro'));

			document.title = 'The Laundro';
			document.querySelector('#my-icon').setAttribute('href', './laundro/favicon.ico');
			document.querySelector('#my-apple-icon').setAttribute('href', './laundro/logo192.png');
			document.querySelector('#my-manifest-placeholder').setAttribute('href', './laundro/manifest.json');
		}
	}, [window.location.href]);

	// console.log("outlets", outlets, error)

	return (
		<div className='App'>
			<Router>
				<Routes>
					<Route exact path='/operator_login' element={<OperatorLogin />} />
					<Route exact path='/operator_register' element={<OperatorRegister />} />
					<Route exact path='/verify' element={<OperatorVerify />} />

					<Route exact path='/admin_login' element={<AdminLogin />} />

					<Route exact path='/distributor_login' element={<DistributorLogin />} />

					<Route exact path='/doPayment' element={<DoPaymentCheck />} />

					<Route exact path='/staff_login' element={<StaffLogin />} />
					<Route exact path='/staff_register' element={<StaffRegister />} />

					<Route exact path='/forgot/password' element={<ForgotPasswordPage />} />

					<Route exact path='/password/reset' element={<VerifyResetPassword />} />
					<Route
						path='/users/all'
						element={
							<MainPageLayout devSideBars={true}>
								<ManagementUserSettingWrapper>
									<UsersListWithRoles />
								</ManagementUserSettingWrapper>
							</MainPageLayout>
						}
					/>

					<Route
						path='/users/roles'
						element={
							<MainPageLayout devSideBars={true}>
								<ManagementUserSettingWrapper>
									<RoleListPage />
								</ManagementUserSettingWrapper>
							</MainPageLayout>
						}
					/>

					<Route
						path='/users/activity'
						element={
							<MainPageLayout devSideBars={true}>
								<ManagementUserSettingWrapper>
									<UserActivityPage />
								</ManagementUserSettingWrapper>
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/mainDashboard'
						element={
							<MainPageLayout devSideBars={true}>
								<SummaryDashboard />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/outletOnlineStatus'
						element={
							<MainPageLayout devSideBars={true}>
								<OutletOnlineStatus />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/op_profile'
						element={
							<MainPageLayout devSideBars={true}>
								<UserProfile />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/op_setting'
						element={
							<MainPageLayout devSideBars={true}>
								<UserSetting />
							</MainPageLayout>
						}
					/>
					<Route exact path='/privacypolicy' element={<PrivacyPolicy />} />
					<Route exact path='/terms&conditions' element={<TermsConditions />} />

					<Route
						exact
						path='/annualOverview'
						element={
							<MainPageLayout devSideBars={true}>
								<SalesPerformance />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/machineUtilization'
						element={
							<MainPageLayout devSideBars={true}>
								<MachineUtilization />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/remoteActivation'
						element={
							<MainPageLayout devSideBars={true}>
								<RemoteActivation />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/priceList'
						element={
							<MainPageLayout devSideBars={true}>
								<PriceList />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/droppriceList'
						element={
							<MainPageLayout devSideBars={true}>
								<DropPriceList />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/priceList2'
						element={
							<MainPageLayout devSideBars={true}>
								<PriceList2 />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/costingList'
						element={
							<MainPageLayout devSideBars={true}>
								<CostingList />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/costingLog'
						element={
							<MainPageLayout devSideBars={true}>
								<CostingLog />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/salesLog'
						element={
							<MainPageLayout devSideBars={true}>
								<SalesLog />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/salesList'
						element={
							<MainPageLayout devSideBars={true}>
								<SalesList />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/promotion'
						element={
							<MainPageLayout devSideBars={true}>
								<PromotionList />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/importData'
						element={
							<MainPageLayout devSideBars={true}>
								<ImportData />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/utilityMonitoring'
						element={
							<MainPageLayout devSideBars={true}>
								<UtilityMonitoring />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/transactions_record'
						element={
							<MainPageLayout devSideBars={true}>
								<TransactionsRecord />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/sales_transaction_record'
						element={
							<MainPageLayout devSideBars={true}>
								<MachineSalesTransactions />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/hourly_sales_transaction'
						element={
							<MainPageLayout devSideBars={true}>
								<HourlySalesTransaction />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/epayment_record'
						element={
							<MainPageLayout devSideBars={true}>
								<EpayRecord />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/terminal_record'
						element={
							<MainPageLayout devSideBars={true}>
								<TerminalRecord />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/overall_epayment_record'
						element={
							<MainPageLayout devSideBars={true}>
								<OverallEpaymentRecord />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/dng_record'
						element={
							<MainPageLayout devSideBars={true}>
								<DNGRecord />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/costing_record'
						element={
							<MainPageLayout devSideBars={true}>
								<CostingRecord />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/manualPay_record'
						element={
							<MainPageLayout devSideBars={true}>
								<ManualPayRecord />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/errorcode_record'
						element={
							<MainPageLayout devSideBars={true}>
								<ErrorCodeRecord />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/bill_record'
						element={
							<MainPageLayout devSideBars={true}>
								<BillRecord />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/vending_record'
						element={
							<MainPageLayout devSideBars={true}>
								<VendingRecord />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/sales_summary'
						element={
							<MainPageLayout devSideBars={true}>
								<SalesSummary />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/salesByMachineType'
						element={
							<MainPageLayout devSideBars={true}>
								<SalesByMachineType />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/systemErrorReport'
						element={
							<MainPageLayout devSideBars={true}>
								<SystemErrorReport />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/reviewDashboard'
						element={
							<MainPageLayout devSideBars={true}>
								<ReviewDashboard />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/inventory'
						element={
							<MainPageLayout devSideBars={true}>
								<Inventory />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/machineLog'
						element={
							<MainPageLayout devSideBars={true}>
								<MachineLogging />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/role/dashboard'
						element={
							<AdminPageLayout devSideBars={false}>
								<DevRoleMainDashboard />
							</AdminPageLayout>
						}
					/>
					<Route
						exact
						path='/roleList'
						element={
							<AdminPageLayout devSideBars={false}>
								<RoleList />
							</AdminPageLayout>
						}
					/>
					<Route
						exact
						path='/userList'
						element={
							<AdminPageLayout devSideBars={false}>
								<RoleUserList />
							</AdminPageLayout>
						}
					/>
					<Route
						exact
						path='/syncList'
						element={
							<AdminPageLayout devSideBars={false}>
								<SyncManagement />
							</AdminPageLayout>
						}
					/>

					<Route
						exact
						path='/distributors/:clientId'
						element={
							<AdminPageLayout devSideBars={false}>
								<DistributorsDashboard />
							</AdminPageLayout>
						}
					/>

					<Route
						exact
						path='/coin/collection'
						element={
							<MainPageLayout devSideBars={true}>
								<CoinCollectionPage />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/coin/collection/group'
						element={
							<MainPageLayout devSideBars={true}>
								<CoinCollectionGroup />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/coin/collection/start'
						element={
							<MainPageLayout devSideBars={true}>
								<StartCollection />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/coin/collection/history'
						element={
							<MainPageLayout devSideBars={true}>
								<CutoffHistory />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/coin/collection/outlet'
						element={
							<MainPageLayout devSideBars={true}>
								<CoinCollectionByOutlet />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/coin/collection/machine'
						element={
							<MainPageLayout devSideBars={true}>
								<CoinCollectionByMachines />
							</MainPageLayout>
						}
					/>
					<Route
						exact
						path='/collection/bill'
						element={
							<MainPageLayout devSideBars={true}>
								<BillCollectionReport />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/inventory/management'
						element={
							<MainPageLayout devSideBars={true}>
								<InventoryManagement />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/inventory/chemicalstock'
						element={
							<MainPageLayout devSideBars={true}>
								<Chemicalstock />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/inventory/inputstock'
						element={
							<MainPageLayout devSideBars={true}>
								<Inputstock />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/inventory/chemicalstocksetting'
						element={
							<MainPageLayout devSideBars={true}>
								<ChemicalStockSetting />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/inventory/chemicalbalancereport'
						element={
							<MainPageLayout devSideBars={true}>
								<ChemicalBalanceReport />
							</MainPageLayout>
						}
					/>

					
					<Route
						exact
						path='/machine/efficiency'
						element={
							<MainPageLayout devSideBars={true}>
								<MachineEfficiency />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/sales/overall'
						element={
							<MainPageLayout devSideBars={true}>
								<SalesSummaryOverall />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/weekly/report'
						element={
							<MainPageLayout devSideBars={true}>
								<WeeklyReport />
							</MainPageLayout>
						}
					/>

					<Route
						exact
						path='/machine/efficiency'
						element={
							<MainPageLayout devSideBars={true}>
								<MachineEfficiency />
							</MainPageLayout>
						}
					/>


					<Route path='*' element={<Navigate to='/mainDashboard' />}></Route>
				</Routes>
			</Router>
		</div>
	);
}

export default App;
